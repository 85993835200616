import axios from "axios"; // Axios serve para acessar o back end e trocar informaçoes.

const apiService = axios.create({ // cria uma nova instancia de configuraçoes personalizaveis.
  baseURL:'https://usaback.illuminatenet.com'  // url que o axios se conectara 
})

apiService.interceptors.request.use(async config =>{  
  const userToken = await localStorage.getItem('NomeDaMykay:paraidentificar')
  function removerAspasDuplas(token) {

    if (token && token.startsWith('"') && token.endsWith('"')) {
      return token.slice(1, -1);
    } else {
      return token;
    }
  } 
  const tokenOriginal = userToken;
  const tokenModificado = removerAspasDuplas(tokenOriginal);

  config.headers.authorization = `Bearer ${tokenModificado}` // Adiciona o Token no Cabeçalho de autorizaçao HTTP.
  console.log("Interceptaçao comcluida", config );
  return config
})

export default apiService