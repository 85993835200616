import App from '../app/App.js';
import Login from '../Login/index.js';
import Register from '../Register/index.js';


import {  // 'Routes' Substituiu o switch
  Navigate,
  Route, 
  Routes, 
  redirect,
  createBrowserRouter,
  NavLink
} from 'react-router-dom'
import ErroPage from '../UrlErropPag/index.js';
  // 'createBrowserRouter' Atualiza a rota. ele esta no index.
  // 'Routes' examana as rotas 'route' para verificar qual delas corresponde ao pedido de camilho URL.
  // 'Route' Executa o Componente, Que esta associado a Um caminho Na 'Url', Caso Esse Caminho seja Colocado. 

function RouterPag () {
  // const {userData} = useUser()

  const router = createBrowserRouter([  
    { 
    path: "/home",
    element: <App />,
    },
    { 
    path: "/login",
    element: <Login />,
    },
    { 
      path: "/register",
      element: <Register />,
    },
    { 
      path: "/",
      element: <App />,
    },
    { 
      path: "*",
      element: <ErroPage />,
    }
    ]);
  
  return router
  //  return (  
  //   <> 
  //     <Routes>
  //       <Route path='/login' element={<Login />} />
  //       <Route path='/register' element={<Register />} />
  //       <Route path='/home' element={localStorage.getItem('NomeDaMykay:paraidentificar') ? <Home /> : <Navigate to="/login" />} /> 
  //       <Route path='/' element={<Navigate to="/home" />} /> 
  //       <Route path='/products' element={localStorage.getItem('NomeDaMykay:paraidentificar') ? <Product /> : <Navigate to="/login" />} /> 
  //       <Route path='/cart' element={localStorage.getItem('NomeDaMykay:paraidentificar') ? <Cart /> : <Navigate to="/login" />} /> 
  //       <Route path='/admin' element={localStorage.getItem('NomeDaMykay:paraidentificar') ? <Admin /> : <Navigate to="/home" />} /> 
  //     </Routes>
  //   </>
  // )
}
  // {!isAdmin && <Header />}  Olha que Jogada boa essa     <Admin /> : <Navigate to="/home" />
export default RouterPag
