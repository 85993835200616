import {
  Main,
  Conatiner,
  ConatinerTxt,
  P
} from './style.js'

export default function ErroPage(){
  
  return(
    <Main>
      <Conatiner>
        <ConatinerTxt>
          404
        </ConatinerTxt>
        <P>This page could not be found.</P>
      </Conatiner> 
    </Main>
  );
}