import React from "react";
import { useForm } from "react-hook-form";
import { Link,useNavigate } from "react-router-dom"; 
import apiService from "../services/api.js";

import {
  Container,
  LoginImage,
  ContainerItens,
  Label,
  Input,
  SignInLink,
  Button,
  Logo,
  Span,
  PError
} from './style.js'

function Login(){
  const navigate = useNavigate()
  const onSubmit = async clientData => {
    console.log('clientData      ',clientData);
    const response = await apiService.post('/login',{ 
      email: clientData.email,
      senha: clientData.password
    },{ validateStatus: () => true }) 
    const { data } = response

    console.log('dataPAssada    ',data);
    const valid = data.user
    console.log('valid   ',valid);
    if(valid){
      console.log('data    ',data.data.nome);

      StoreLocal()
      async function StoreLocal(){
        await localStorage.setItem('NomeDaMykay:paraidentificar', JSON.stringify(data.token))
        await localStorage.setItem('nome', data.data.nome)
      }
      navigate("/home", { state: { nameUsers: data} });
      // return window.location.href = '/home';
    }else{
    document.getElementById('NotFound').innerHTML = 'This email and password combination is incorrect'
    }
  }


  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();


  return (
    <Container>
      <ContainerItens>
        <PError id='NotFound' ></PError>
        <h1> Login</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
          {/* type="email" pelo fato de estar dentro do form é configurado automaticamente assim como o type="password" Isso é do html  caso nao queiramos passamos uma configuraçao no form 'noValidate' para validar somente pelo reactHookForm*/}
          <Label>Email</ Label>
          {/*// ...register chama a funçao register e cadastra o campo e ja da um name  */}
          {/*Podemos passar validaçoes no segundo parametro de '...register()' */}
          <Input placeholder="Email" id="email" {...register('email', { required: true })} error={errors.email?.type} />
          {/*O type do erro é o que definimos no objeto da segunda prop do ...register*/}
          {errors.email && errors.email.type === "required" && <Span>Preencha O Campo De Email</Span>}  {/* Se ouver um erro em : 'errors.email', Ele exibira a mensagem de erro. */}
          <Label>Senha</ Label>
          <Input placeholder="Senha"  id="password" {...register('password', { required: true, maxLength: 15 })} error={errors.password?.type} />
            {errors.password && errors.password.type === "required" &&  <Span>Preencha O Campo De Senha</Span>}   {/*errors.NomeDoCampo.message     Para exibir o erro ao usuario*/}
            {errors.password && errors.password.type === "maxLength" &&  <Span>Sua Senha Não Pode Conter Mais do Que 15 caracteres </Span>}
          <Button type="submit" > Logar </ Button>  {/* type="submit"  por estar dentro do formulario vai submeter os dados */}
          </form>
          
        <SignInLink>
        Não possui conta ? <Link style={{color:'#b5b5b5' }}  to="/register">Sing-up</Link>  { /* to="/register" Aponta para qual url deve ser acessada ao clicar nas palavras */ }
        </SignInLink>
      </ ContainerItens>
    </ Container>
  )
  
}
// type="submit" Faz com que  onSubmite Seja executado.

export default Login