import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { validate,AskinAlert } from './Alert.js'
import apiService from "../services/api.js";

import {
  Container,
  LoginImage,
  ContainerItens,
  Label,
  Input,
  SignInLink,
  Button,
  Logo,
  Span,
  InfoAlert, 
  DivAlert,
  SpanRed
} from './style.js'
import styled from "styled-components";
import { v4 } from "uuid";

function Register(){

  const {
    register, 
    handleSubmit, 
    watch,
    formState: { errors }, 
  } = useForm()

  const HandleonSubmitData = async clientData => {
    console.log('clientData      ',clientData);
    const response = await apiService.post('/register',{  
      email: clientData.email,
      senha: clientData.password,
      id: v4(), 
      nome: clientData.name
    },{ validateStatus: () => true }) 
  
    const { data } = response
    const valid = data.register
    console.log('valid   ',valid);
    if(valid == true){
      return window.location.href = '/login';
    }
    document.getElementById('NotFound').innerHTML = 'Essa combinação de e-mail e senha está incorreta'
  }  

  return (
    <Container>
      <ContainerItens>
        <h1> Cadastre-se </h1>
         <form onSubmit={handleSubmit(HandleonSubmitData)}> 

          <InfoAlert>
            <Label>Nome</ Label>
            {errors.name && errors.name.type === "required" &&  <DivAlert><Span>Nome é Obrigatorio</Span></DivAlert>} 
          </InfoAlert>
          <Input placeholder="Nome" type="text" id="name" {...register('name', { required: true })} error={errors.name?.type}/>  
          
          <InfoAlert>
            <Label>Email</ Label>
            {errors.email && errors.email.type === "required" && <DivAlert><Span >Email é Obrigatorio</Span></DivAlert> } 
          </InfoAlert>
          <Input placeholder="Email" id="email" {...register('email', { required: true })} error={errors.email?.type} />   
          <InfoAlert>
            <Label>Senha</ Label>

              {errors.password && errors.password.type === "required" && <DivAlert><Span id="AlertMensage" >Senha é Obrigatorio</Span></DivAlert> }   
               {errors.password && errors.password.type === "minLength" &&  <DivAlert><Span>Sua Senha Nao pode possuir mais que 5 caracteres</Span></DivAlert>}

          </InfoAlert>
          <Input placeholder="Sua Senha" type="password"  id="password" {...register('password', { required: true, minLength: 5 })} error={errors.password?.type}  onChange={validate}
           />   
          <SpanRed id="validSAmePassword1" ></SpanRed>
          <InfoAlert>
            <Label>Confirme a Senha</ Label>

              {errors.ConfirmPassword && errors.ConfirmPassword.type === "required" &&<DivAlert> <Span id="AlertMensage" >Senha é Obrigatorio</Span></DivAlert> }
               {errors.ConfirmPassword && errors.ConfirmPassword.type === "minLength" &&  <DivAlert><Span>Sua Senha Nao pode possuir mais que 5 caracteres</Span></DivAlert>}

          </InfoAlert>
          <Input placeholder="Confirme Sua Senha" id="ConfirmPassword"  type="password"  {...register('ConfirmPassword', { required: true, minLength: '5' })} error={errors.ConfirmPassword?.type}  onChange={validate}
            />   
           <SpanRed id="validSAmePassword2" ></SpanRed>

          <Button type="submit" > Confirme </ Button> 
        </form>
        <SignInLink>
        Já possui conta ? <Link style={{color:'#b5b5b5' }} to="/login">Sing-in</Link>
        </SignInLink>
      </ ContainerItens>
    </ Container>
  )
}


export default Register