import styled from "styled-components";

export const Main = styled.div`
width: 100vw;
height: 100vh;
display: flex;
align-items: center;
justify-content: center;
`
export const Conatiner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 33px;
`
export const ConatinerTxt = styled.p`
  font-size: 24px;
  padding-right: 20px;
  border-right: 1px solid black;
`
export const P = styled.p`
  padding-left: 20px;
  min-width: 240px;
`